import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import SendMessageButton from "components/Molecules/SendMessageButton/SendMessageButton";
import { useAuth0 } from "@auth0/auth0-react";
import useMyProfile from "hooks/useMyProfile";
import { PROFILE_TYPE } from "entities/Profile";

interface UpcomingSessionProps {
  session: MentorSessionQueryResult;
}

const UpcomingSession = ({ session }: UpcomingSessionProps) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { type } = useMyProfile();

  const joinSession = () =>
    session.videoRoomLink && window.open(session.videoRoomLink, "_blank");

  const handleRescheduleSession = async () => {
    posthog.capture("mentor_clicked_reschedule_session", {
      status: session.status,
      session_id: session._id,
    });
    navigate(`/reschedule/${session._id}`);
  };

  const cancelSession = () => {
    window.open(
      "mailto:team@mentorpass.co?subject=Cancel%20Session&body=I'd%20like%20to%20cancel%20a%20session.",
      "_blank"
    );
  };

  // Get the current user's user_id
  const current_user_id = user ? user["https://api.mentorpass.co/user_id"] as string : "";
  
  // Debug session data
  console.log('UpcomingSession - Debug Info:');
  console.log('Session:', session);
  console.log('Current user type:', type);
  console.log('Current user ID:', current_user_id);
  console.log('Mentee profile:', session.mentee_profile);
  console.log('Mentee user_id:', session.mentee_profile.user_id);

  // Set mentor and mentee user IDs correctly
  // Since we're in the mentor view, the current user is the mentor
  // and the mentee is from the session data
  const mentor_user_id = current_user_id;
  const mentee_user_id = session.mentee_profile.user_id;

  console.log('Final mentor_user_id:', mentor_user_id);
  console.log('Final mentee_user_id:', mentee_user_id);

  return (
    <MentorSessionCard
      session={session}
      actions={
        <div className={styles.button_container}>
          <button className={styles.blue_button} onClick={joinSession}>
            Join Session
          </button>
          <SendMessageButton 
            mentor_user_id={mentor_user_id}
            mentee_user_id={mentee_user_id}
            className={styles.message_button}
          />
          <button
            className={styles.black_button}
            onClick={handleRescheduleSession}
          >
            Reschedule Session
          </button>
          <button className={styles.red_button} onClick={cancelSession}>
            Cancel Session
          </button>
        </div>
      }
    />
  );
};

export default UpcomingSession;
