import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useMessaging from 'hooks/useMessaging';
import useMyProfile from 'hooks/useMyProfile';
import { PROFILE_TYPE } from 'entities/Profile';
import styles from './SendMessageButton.module.css';

interface SendMessageButtonProps {
  // The mentee's user_id (not profile_id)
  mentee_user_id: string;
  // The mentor's user_id (not profile_id)
  mentor_user_id: string;
  className?: string;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({ 
  mentee_user_id, 
  mentor_user_id,
  className
}) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { type } = useMyProfile();
  const { getConversationByMentorAndMentee, createConversation, is_loading } = useMessaging();

  const handleSendMessage = async () => {
    if (is_loading || !user) {
      return;
    }

    // Debug logging
    console.log('SendMessageButton - Debug Info:');
    console.log('Current user type:', type);
    console.log('Current user ID:', user["https://api.mentorpass.co/user_id"]);
    console.log('Mentor user ID passed to component:', mentor_user_id);
    console.log('Mentee user ID passed to component:', mentee_user_id);

    try {
      // Debug what we're sending to the API
      console.log('Calling getConversationByMentorAndMentee with:');
      console.log('  mentor_user_id:', mentor_user_id);
      console.log('  mentee_user_id:', mentee_user_id);

      // Try to find an existing conversation using user_ids (not profile_ids)
      let conversation_id = await getConversationByMentorAndMentee(
        mentor_user_id,
        mentee_user_id
      );

      console.log('API response - conversation_id:', conversation_id);

      // If no conversation exists, create one
      if (!conversation_id) {
        console.log('No existing conversation found, creating a new one');
        
        // Determine if current user is a mentor
        const isCurrentUserMentor = type === PROFILE_TYPE.MENTOR;
        console.log(`Creating conversation as ${isCurrentUserMentor ? 'mentor' : 'mentee'} with other user:`, isCurrentUserMentor ? mentee_user_id : mentor_user_id);
        
        // Pass the appropriate user ID and role flag
        conversation_id = await createConversation(
          isCurrentUserMentor ? mentee_user_id : mentor_user_id,
          isCurrentUserMentor
        );
        console.log('New conversation created with ID:', conversation_id);
      }

      if (conversation_id) {
        // If conversation exists or was created, navigate to it
        console.log('Navigating to conversation:', conversation_id);
        navigate('/messaging', {
          state: {
            conversationId: conversation_id,
          },
        });
      } else {
        // If still no conversation, use temporal state as fallback
        console.log('Creating temporal conversation with:');
        console.log('  mentor: { user_id:', mentor_user_id, '}');
        console.log('  mentee: { user_id:', mentee_user_id, '}');
        
        navigate('/messaging', {
          state: {
            temporal: true,
            mentor: { user_id: mentor_user_id },
            mentee: { user_id: mentee_user_id },
          },
        });
      }
    } catch (error) {
      console.error('Error navigating to conversation:', error);
    }
  };

  return (
    <button 
      className={`${styles.send_message_button} ${className || ''}`} 
      onClick={handleSendMessage}
      disabled={is_loading}
    >
      Send Message
    </button>
  );
};

export default SendMessageButton; 