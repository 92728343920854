import { useState, useEffect, useCallback } from "react";
import { API_URL, createHeaders } from "../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useLiveSocket } from "components/Organisms/Realtime/LiveSocket.provider";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useMessagingAnalytics } from "../services/analytics/messaging";
import { differenceInSeconds } from "date-fns";

const useMessaging = () => {
  const [conversations, setConversations] = useState<any[]>([]);
  const [fetchedConversations, setFetchedConversations] =
    useState<boolean>(false);
  const [temporalConversations, setTemporalConversations] = useState<any[]>([]);
  const [temporalConversationReplaced, setTemporalConversationReplaced] =
    useState<number>(0);
  const [error, setError] = useState<string | undefined>();
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const { socket } = useLiveSocket();
  const dispatch = useAppDispatch();
  const unreadMessages = useAppSelector(
    (state) => state.messagingReducer.unreadMessages || {}
  );
  const messagingAnalytics = useMessagingAnalytics();

  const addTemporalConversation = async (mentor: any, mentee: any) => {
    const temporalConversation = {
      _id: uuidv4(),
      mentor,
      mentee,
      mentee_id: mentee._id,
      mentor_id: mentor.user_id,
      messages: [],
      configuration: {
        settings: { type: "Everyone", messages: 0 },
      },
      status: "active",
      type: "prospect",
      created_at: new Date(),
      temporal: true,
    };
    setTemporalConversations((prevConversations) => [
      ...prevConversations,
      temporalConversation,
    ]);
    return temporalConversation;
  };

  const getConversations = async (profile: string): Promise<void> => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/messaging/conversations/${profile}`,
        {
          headers: createHeaders(token),
        }
      );
      if (response.status === 200) {
        const conversations = await response.json();
        setConversations(conversations);
      }
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
      setError(String(error));
    } finally {
      setFetchedConversations(true);
      setIsLoading(false);
    }
  };

  const getConversationByMentorAndMentee = async (
    mentor_id: string,
    mentee_id: string
  ): Promise<string | null> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/messaging/conversation/mentor/${mentor_id}/mentee/${mentee_id}`,
        {
          headers: createHeaders(token),
        }
      );
      if (response.status === 200) {
        const conversationId = await response.json();
        return conversationId;
      }
      return null;
    } catch (error) {
      console.error(
        "Failed to fetch conversation by mentor and mentee:",
        error
      );
      setError("Failed to find conversation");
      return null;
    }
  };

  const createConversation = async (
    other_user_id: string,
    is_current_user_mentor: boolean = true,
    source: string = "unknown"
  ): Promise<string | null> => {
    let conversationId: string | null = null;
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      
      // Determine which parameter to use based on the user's role
      const payload = is_current_user_mentor 
        ? { mentor_id: other_user_id } // Current user is mentor, other user is mentee
        : { mentor: other_user_id }; // Current user is mentee, other user is mentor
      
      const response = await fetch(`${API_URL}/messaging/conversation`, {
        method: "POST",
        mode: "cors",
        headers: createHeaders(token),
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.status === 200) {
        conversationId = data._id;
        
        // Track conversation creation
        if (conversationId) {
          messagingAnalytics.trackConversationCreated(
            conversationId,
            other_user_id,
            is_current_user_mentor,
            source
          );
        }
      }
    } catch (error: unknown) {
      setError("Failed to create conversation");
      messagingAnalytics.trackMessagingError(
        typeof error === 'object' && error !== null && 'name' in error ? String(error.name) : "Error",
        typeof error === 'object' && error !== null && 'message' in error ? String(error.message) : "Failed to create conversation",
        "createConversation"
      );
    } finally {
      setIsLoading(false);
    }
    return conversationId;
  };

  // Helper functions for analytics
  const findConversation = (conversationId: string) => {
    return [...conversations, ...temporalConversations].find(
      (conv) => conv._id === conversationId
    );
  };

  const isFirstMessageInConversation = (conversationId: string): boolean => {
    const conversation = findConversation(conversationId);
    return conversation ? (conversation.messages?.length || 0) === 0 : false;
  };

  const getConversationMessageCount = (conversationId: string): number => {
    const conversation = findConversation(conversationId);
    return conversation ? conversation.messages?.length || 0 : 0;
  };

  const getLastMessageDate = (conversationId: string): Date | null => {
    const conversation = findConversation(conversationId);
    if (!conversation || !conversation.messages || conversation.messages.length === 0) {
      return null;
    }
    
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    return lastMessage.date ? new Date(lastMessage.date) : null;
  };

  const sendMessage = async (
    conversationId: string,
    messageText: string,
    sender_id: string,
    temporal?: boolean,
    mentorId?: string
  ) => {
    if (!socket) return;
    if (!messageText) return;
    const tempId = uuidv4();
    const message = {
      id: tempId,
      message: messageText,
      type: "text",
      date: new Date(),
      status: "pending",
      conversation_id: conversationId,
      temporal,
      mentor_id: mentorId,
    };

    // Get message count before adding new message
    const isFirstMessage = isFirstMessageInConversation(conversationId);
    const messageCount = getConversationMessageCount(conversationId);

    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv._id === conversationId
          ? {
              ...conv,
              messages: [
                ...conv.messages,
                { ...message, sender_id: sender_id },
              ],
            }
          : conv
      )
    );

    socket.emit("chat_message", {
      message,
    });

    // Track message sent
    messagingAnalytics.trackMessageSent(
      conversationId,
      messageText,
      sender_id,
      isFirstMessage,
      messageCount,
      !!temporal
    );

    return;
  };

  const markMessagesAsRead = async (conversationId: string): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(
        `${API_URL}/messaging/conversation/${conversationId}/markAsRead`,
        {
          method: "POST",
          headers: createHeaders(token),
        }
      );
      
      // Get unread count before marking as read
      const unreadCount = unreadMessages[conversationId] || 0;
      
      // Calculate time since last message
      const lastMessageDate = getLastMessageDate(conversationId);
      let timeSinceLastMessage: number | null = null;
      
      if (lastMessageDate) {
        timeSinceLastMessage = differenceInSeconds(new Date(), lastMessageDate);
      }
      
      // Track messages read if there were unread messages
      if (unreadCount > 0) {
        messagingAnalytics.trackMessagesRead(
          conversationId,
          unreadCount,
          timeSinceLastMessage
        );
      }
      
      dispatch({ type: "MARK_MESSAGES_AS_READ", payload: { conversationId } });
    } catch (error: unknown) {
      console.error("Failed to mark messages as read:", error);
      messagingAnalytics.trackMessagingError(
        typeof error === 'object' && error !== null && 'name' in error ? String(error.name) : "Error",
        typeof error === 'object' && error !== null && 'message' in error ? String(error.message) : "Failed to mark messages as read",
        "markMessagesAsRead",
        conversationId
      );
    }
  };

  const fetchUnreadConversations = async (): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/messaging/unread-conversations`,
        {
          headers: createHeaders(token),
        }
      );
      if (response.status === 200) {
        const unreadConversations = await response.json();
        const unreadMessages = unreadConversations.reduce(
          (acc: any, conv: any) => {
            acc[conv._id] = conv.unreadCount || 0;
            return acc;
          },
          {}
        );
        dispatch({ type: "SET_UNREAD_MESSAGES", payload: unreadMessages });
      }
    } catch (error: unknown) {
      return;
    }
  };

  const updateMessagingSettings = async (
    settings: { type: string; messages: number; isUnlimited?: boolean }
  ): Promise<void> => {
    try {
      // Get previous settings before updating
      const previousSettings = await getMentorSettings();
      
      const token = await getAccessTokenSilently();
      await fetch(
        `${API_URL}/messaging/mentor/settings`,
        {
          method: "POST",
          headers: createHeaders(token),
          body: JSON.stringify(settings),
        }
      );

      if (socket) {
        socket.emit("update_conversation_settings", {
          settings,
        });
      }
      
      // Track settings update
      messagingAnalytics.trackConversationSettingsUpdated(
        previousSettings,
        settings
      );
    } catch (error: unknown) {
      console.error("Failed to update conversation settings:", error);
      messagingAnalytics.trackMessagingError(
        typeof error === 'object' && error !== null && 'name' in error ? String(error.name) : "Error",
        typeof error === 'object' && error !== null && 'message' in error ? String(error.message) : "Failed to update conversation settings",
        "updateMessagingSettings"
      );
    }
  };

  const getMentorSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/messaging/mentor/settings`,
        {
          headers: createHeaders(token),
        }
      );
      if (response.status === 200) {
        return await response.json();
      }
    } catch (error) {
      console.error("Failed to fetch conversation settings:", error);
    }
    return { type: "Everyone", messages: 0 }; // Default settings with unlimited messages
  };

  const getMentorSettingsById = useCallback(async (mentor_id: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/messaging/mentor/${mentor_id}/settings`,
        {
          headers: createHeaders(token),
        }
      );
      if (response.status === 200) {
        return await response.json();
      }
    } catch (error) {
      console.error("Failed to fetch mentor settings:", error);
    }
    return { type: "Everyone", messages: 0 }; // Default settings with unlimited messages
  }, [getAccessTokenSilently]);

  const deleteConversation = async (conversationId: string): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/messaging/conversation/${conversationId}`, {
        method: 'DELETE',
        headers: createHeaders(token),
      });
      
      if (response.status === 200) {
        setConversations((prevConversations) => 
          prevConversations.filter((conv) => conv._id !== conversationId)
        );
      }
    } catch (error) {
      console.error("Failed to delete conversation:", error);
      setError(String(error));
    }
  };

  useEffect(() => {
    if (!socket) return;

    const handleMessageStatusUpdate = (message: any) => {
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv._id === message.conversation_id
            ? {
                ...conv,
                messages: conv.messages.map((msg: any) =>
                  msg.id === message.id
                    ? { ...msg, status: message.status }
                    : msg
                ),
              }
            : conv
        )
      );
    };

    const handleNewMessage = (data: any) => {
      const { message, conversation } = data;
      setConversations((prevConversations) => {
        const conversationExists = prevConversations.some(
          (conv) => conv._id === conversation._id
        );

        if (conversationExists) {
          return prevConversations.map((conv) =>
            conv._id === conversation._id
              ? { ...conv, messages: [...conv.messages, message] }
              : conv
          );
        } else {
          // If conversation doesn't exist (e.g., newly started conversation)
          return [
            ...prevConversations,
            {
              ...conversation,
              messages: [message],
            },
          ];
        }
      });
    };

    const handleUnreadMessage = (data: any) => {
      const { conversation_id } = data;
      dispatch({
        type: "UPDATE_UNREAD_MESSAGES",
        payload: { conversation_id },
      });
    };

    const handleConversationSettingsUpdated = (data: any) => {
      const { mentorId, settings } = data;
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.mentor_id.toString() === mentorId
            ? { ...conv, configuration: { settings } }
            : conv
        )
      );
    };

    const handleTemporalConversationCreated = (data: any) => {
      const { conversation } = data;
      setTemporalConversations([]);
      setConversations((prevConversations) => [
        ...prevConversations,
        conversation,
      ]);
      setTemporalConversationReplaced((prev) => prev + 1);
    };

    socket.on("chat_message_status", handleMessageStatusUpdate);
    socket.on("chat_message", handleNewMessage);
    socket.on("unread_message", handleUnreadMessage);
    socket.on(
      "temporal_conversation_created",
      handleTemporalConversationCreated
    );
    socket.on(
      "conversation_settings_updated",
      handleConversationSettingsUpdated
    );

    return () => {
      socket.off("chat_message_status", handleMessageStatusUpdate);
      socket.off("chat_message", handleNewMessage);
      socket.off("unread_message", handleUnreadMessage);
      socket.off(
        "temporal_conversation_created",
        handleTemporalConversationCreated
      );
      socket.off(
        "conversation_settings_updated",
        handleConversationSettingsUpdated
      );
    };
  }, [socket]);

  return {
    conversations,
    fetchedConversations,
    temporalConversations,
    is_loading,
    error,
    temporalConversationReplaced,
    getConversations,
    createConversation,
    fetchUnreadConversations,
    sendMessage,
    markMessagesAsRead,
    updateMessagingSettings,
    addTemporalConversation,
    getConversationByMentorAndMentee,
    getMentorSettings,
    getMentorSettingsById,
    deleteConversation,
  };
};

export default useMessaging;
