import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { io, Socket } from "socket.io-client";
import { SOCKET_URL } from "utils/api";
import { useDispatch } from "react-redux";

interface LiveSocketContextValue {
  socket: Socket | null;
}

const LiveSocketContext = createContext<LiveSocketContextValue>({
  socket: null,
});

export const useLiveSocket = () => useContext(LiveSocketContext);

export const LiveSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const connectSocket = async () => {
      if (isAuthenticated && !socket) {
        try {
          const token = await getAccessTokenSilently();
          const newSocket = io(SOCKET_URL, {
            auth: { token },
            path: "/api/ws",
            transports: ["websocket"],
          });
          setSocket(newSocket);

          newSocket.on("connect", () => {
            console.log("Connected to socket server");
          });

          newSocket.on("disconnect", () => {
            console.log("Disconnected from socket server");
          });

          return () => {
            newSocket.close();
          };
        } catch (error) {
          console.error("Failed to connect to socket:", error);
        }
      }
    };

    connectSocket();
  }, [isAuthenticated, getAccessTokenSilently, socket, dispatch]);

  return (
    <LiveSocketContext.Provider value={{ socket }}>
      {children}
    </LiveSocketContext.Provider>
  );
};
