import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "components/Organisms/Header";
import styles from "./MyChats.module.css";
import useMessaging from "hooks/useMessaging";
import AvatarPlaceholder from "../../../../images/avatar-placeholder.gif";
import SegmentedProfileSwitcher from "./SegmentedProfileSwitcher";
import useMyProfile from "hooks/useMyProfile";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";
import { SendIcon, BackArrow, Settings } from "components/Icons";
import { format, differenceInMinutes } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import ConversationSettingsModal from "./ConversationSettingsModal";
import MessagingPartners from "./MessagingPartners";
import RelationshipSummary from "./RelationshipSummary";
import ConversationListSkeleton from "./ConversationListSkeleton";

const MyChats = () => {
  const { user } = useAuth0();
  const { type } = useMyProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const [newMessage, setNewMessage] = useState("");
  const {
    conversations,
    fetchedConversations,
    temporalConversations,
    temporalConversationReplaced,
    getConversations,
    sendMessage,
    markMessagesAsRead,
    updateMessagingSettings,
    addTemporalConversation,
    deleteConversation,
    createConversation,
  } = useMessaging();
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  // TODO: Implement proper filtering for Prospects/Mentees tabs
  // const [activeTab, setActiveTab] = useState<
  //   "mentors" | "prospects" | "mentees" | null
  // >(null);
  const [hoveredConversationId, setHoveredConversationId] = useState<string | null>(null);

  const { user_id } = useMemo(() => processAuthClaims(user), [user]);
  const { temporal, mentor, conversationId } = location.state || {};

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // State to manage sidebar visibility
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  // State to determine if the view is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  const unreadMessages = useAppSelector(
    (state) => state.messagingReducer.unreadMessages
  );

  const [error, setError] = useState<string | null>(null);

  // Function to show error temporarily and clear it after a delay
  const showTemporaryError = (errorMessage: string) => {
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 5000); // Clear error after 5 seconds
  };

  // Handle temporal conversation creation and selection
  useEffect(() => {
    if (temporal && mentor) {
      createTemporalConversation();
    }
  }, [temporal, mentor]);

  useEffect(() => {
    if (selectedConversation) {
      const updatedConversation = conversations.find(
        (conv) => conv.mentor_id === selectedConversation.mentor_id
      );
      if (updatedConversation) {
        setSelectedConversation(updatedConversation);
      }
    }
  }, [temporalConversationReplaced]);

  // Handle existing conversation selection from profile
  useEffect(() => {
    if (conversationId && conversations.length > 0 && fetchedConversations) {
      setSelectedConversation(
        [...conversations, ...temporalConversations].find(
          (conv) => conv._id === conversationId
        )
      );
    }
  }, [fetchedConversations, conversationId]);

  useEffect(() => {
    if (selectedConversation) {
      const updatedConversation = conversations.find(
        (conv) => conv._id === selectedConversation._id
      );
      if (updatedConversation) {
        setSelectedConversation(updatedConversation);
      }
    }
  }, [conversations]);

  // Update selectedConversation when conversations change
  useEffect(() => {
    if (selectedConversation) {
      const updatedConversation = conversations.find(
        (conv) => conv._id === selectedConversation._id
      );
      if (updatedConversation) {
        setSelectedConversation(updatedConversation);
      }
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (type) {
      getConversations(type);
      // TODO: Re-enable tab initialization when implementing proper filtering
      // if (type === "mentor") {
      //   setActiveTab("prospects");
      // }
      // if (type === "mentee") {
      //   setActiveTab("mentors");
      // }
    }
  }, [type]);

  // Select the most recent conversation by default when conversations are loaded
  useEffect(() => {
    if (conversations.length > 0 && !selectedConversation && !conversationId && !temporal) {
      const sortedConversations = getFilteredAndSortedConversations();
      if (sortedConversations.length > 0) {
        setSelectedConversation(sortedConversations[0]);
      }
    }
  }, [conversations, fetchedConversations]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedConversation?.messages]);

  // Listen for window resize events to update isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToMentorProfile = async (username: string) => {
    navigate(`/profile/${username}`);
  };

  const goToBooking = () => {
    navigate(`/profile/${selectedConversation.mentor?.slug}/book-session`);
  };

  const createTemporalConversation = async () => {
    const temporalConversation = await addTemporalConversation(mentor, user);
    setSelectedConversation(temporalConversation);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        return '';
      }
      
      const now = new Date();
      const oneYearAgo = new Date(now);
      oneYearAgo.setFullYear(now.getFullYear() - 1);

      if (date.toDateString() === now.toDateString()) {
        return format(date, "h:mm a");
      } else if (date > oneYearAgo) {
        return format(date, "MMM d");
      } else {
        return format(date, "MMM d, yyyy");
      }
    } catch (error) {
      console.error("Error formatting date:", error);
      return '';
    }
  };

  const groupMessages = (messages: any[]) => {
    const grouped: any[] = [];
    let currentGroup: any[] = [];

    messages.forEach((message, index) => {
      if (!message) {
        return;
      }
      
      const previousMessage = messages[index - 1];
      
      let timeGap = 0;
      if (previousMessage && message.date && previousMessage.date) {
        try {
          const messageDate = new Date(message.date);
          const prevMessageDate = new Date(previousMessage.date);
          
          // Check if dates are valid
          if (!isNaN(messageDate.getTime()) && !isNaN(prevMessageDate.getTime())) {
            timeGap = differenceInMinutes(messageDate, prevMessageDate);
          }
        } catch (error) {
          console.error("Error calculating time gap:", error);
        }
      }

      if (
        currentGroup.length === 0 ||
        (previousMessage &&
          previousMessage.sender_id === message.sender_id &&
          timeGap < 3)
      ) {
        currentGroup.push(message);
      } else {
        grouped.push(currentGroup);
        currentGroup = [message];
      }
    });

    if (currentGroup.length > 0) {
      grouped.push(currentGroup);
    }

    return grouped;
  };

  const handleSelectConversation = (conversation: any) => {
    setSelectedConversation(conversation);
    if (!conversation.temporal) {
      markMessagesAsRead(conversation._id);
    }
    if (isMobile) {
      setIsSidebarVisible(false);
    }
  };

  const toggleSidebar = () => {
    setSelectedConversation(null);
    setIsSidebarVisible(!isSidebarVisible);
  };

  const focusConversation = () => {
    if (selectedConversation && !selectedConversation.temporal) {
      markMessagesAsRead(selectedConversation._id);
    }
  };

  const handlePartnerSelect = async (partner: any) => {
    try {
      if (!partner || !partner.user_id) {
        console.error("Invalid partner object:", partner);
        return;
      }

      console.log("Selecting partner for messaging:", partner);

      // Get the source from the partner object, defaulting to "messaging_partners" if not present
      const source = partner.source || "messaging_partners";

      // Check if a conversation already exists with this partner
      const existingConversation = [...conversations, ...temporalConversations].find(
        (conv) => 
          (user_id === conv.mentor?.user_id && conv.mentee?.user_id === partner.user_id) ||
          (user_id === conv.mentee?.user_id && conv.mentor?.user_id === partner.user_id)
      );

      if (existingConversation) {
        console.log("Found existing conversation:", existingConversation);
        // If conversation exists, select it
        handleSelectConversation(existingConversation);
      } else {
        console.log("Creating new temporal conversation with partner:", partner);
        
        // Create mentor and mentee objects using the IDs from the partner object
        const mentor = {
          user_id: partner.mentor_user_id,
          // Add any other required fields for the mentor
        };
        
        const mentee = {
          user_id: partner.mentee_user_id,
          _id: partner._id || partner.user_id, // Use user_id as fallback for _id
          given_name: partner.given_name || "",
          family_name: partner.family_name || "",
          profileImage: partner.profileImage || null
        };
        
        console.log("Creating temporal conversation with:", { mentor, mentee });
        const temporalConversation = await addTemporalConversation(mentor, mentee);
        
        if (temporalConversation) {
          console.log("Created temporal conversation:", temporalConversation);
          
          // We don't need to modify the temporalConversation object directly
          // Just select the conversation with the source information
          handleSelectConversation(temporalConversation);
          
          // Store the source for later use when creating real conversation
          localStorage.setItem(`conversation_source_${temporalConversation._id}`, source);
        } else {
          console.error("Failed to create temporal conversation");
        }
      }
    } catch (error) {
      console.error("Error in handlePartnerSelect:", error);
    }
  };

  const preSendMessage = async () => {
    if (!newMessage.trim()) return; // Don't send empty messages
    
    try {
      // If this is a temporal conversation, create a real one first
      if (selectedConversation.temporal) {
        // Determine if the current user is a mentor or mentee based on profile type
        const is_current_user_mentor = type === "mentor";
        
        // Get the other user's ID based on the current user's role
        const other_user_id = is_current_user_mentor 
          ? selectedConversation.mentee?.user_id 
          : selectedConversation.mentor?.user_id;
        
        // Get the source from localStorage if it exists, otherwise default to "chat_message"
        const source = localStorage.getItem(`conversation_source_${selectedConversation._id}`) || "chat_message";
        
        // Create an actual conversation in the database
        const conversationId = await createConversation(other_user_id, is_current_user_mentor, source);
        
        // Clean up the localStorage item
        localStorage.removeItem(`conversation_source_${selectedConversation._id}`);
        
        if (conversationId) {
          // Use the new conversation ID for sending the message
          await sendMessage(
            conversationId,
            newMessage,
            user_id,
            false, // no longer temporal
            selectedConversation.mentor?.user_id
          );
          setNewMessage("");
        } else {
          // Show error notification if conversation creation failed
          showTemporaryError("Failed to send message. Please try again later.");
          return; // Don't clear the message input so user can try again
        }
      } else {
        // For existing conversations, just send the message normally
        await sendMessage(
          selectedConversation._id,
          newMessage,
          user_id,
          selectedConversation.temporal,
          selectedConversation.mentor?.user_id
        );
        setNewMessage("");
      }
    } catch (err) {
      // Handle any unexpected errors
      console.error("Error sending message:", err);
      showTemporaryError("Failed to send message. Please try again later.");
    }
  };

  const saveSettings = async (settings: {
    type: string;
    isUnlimited: boolean;
    messages: number;
  }) => {
    await updateMessagingSettings(settings);
  };

  // Render the messaging limit notification
  const renderMessagingLimitNotification = () => {
    if (type === "mentor" || !selectedConversation) return null;
    
    let configuration = {
      settings: {
        type: "Everyone",
        messages: 0,
        isUnlimited: true,
      },
    };
    
    if (selectedConversation.configuration) {
      configuration = selectedConversation.configuration;
      
      // Override for backwards compatibility - same logic as in shouldShowInput
      if (configuration.settings.messages === 0 && configuration.settings.isUnlimited === undefined) {
        configuration.settings.isUnlimited = true;
      }
    }
    
    const {
      type: settingsType,
      isUnlimited,
      messages: maxMessages,
    } = configuration.settings;
    
    const { type: conversationType } = selectedConversation;
    
    // Don't show for mentees or if unlimited
    if (conversationType === "mentee" || isUnlimited) return null;
    
    // Only show for prospects with limited messages
    if (settingsType === "Everyone" && conversationType === "prospect") {
      const currentMessageCount = selectedConversation.messages?.length || 0;
      const remaining_messages = Math.max(0, maxMessages - currentMessageCount);
      
      return (
        <div className={styles.subtleMessageLimit}>
          This mentor allows limited messages before booking a session. 
          You have {remaining_messages} of {maxMessages} messages remaining.
        </div>
      );
    }
    
    return null;
  };

  // Replace the existing shouldShowInput() function with this updated version
  const shouldShowInput = () => {
    if (type === "mentor") {
      return true;
    }
    if (!selectedConversation) return false;

    let configuration = {
      settings: {
        type: "Everyone",
        messages: 0,
        isUnlimited: true,
      },
    };
    if (selectedConversation.configuration) {
      configuration = selectedConversation.configuration;
      
      // Override for backwards compatibility - if isUnlimited isn't explicitly set but messages is 0,
      // treat it as unlimited (this aligns with the server-side default settings)
      if (configuration.settings.messages === 0 && configuration.settings.isUnlimited === undefined) {
        configuration.settings.isUnlimited = true;
      }
    }

    const {
      type: settingsType,
      isUnlimited,
      messages: maxMessages,
    } = configuration.settings;
    const { type: conversationType, messages } = selectedConversation;

    if (settingsType === "Everyone") {
      if (conversationType === "mentee") return true;
      if (isUnlimited) return true;
      if (
        conversationType === "prospect" &&
        (messages?.length || 0) < maxMessages
      )
        return true;
    }

    if (settingsType === "Mentees" || settingsType === "Nobody") {
      if (conversationType === "mentee") return true;
    }

    return false;
  };

  const getFilteredAndSortedConversations = () => {
    const allConversations = [...temporalConversations, ...conversations];

    // Separate unread and read conversations
    const unreadConversations = allConversations.filter(
      (conversation) => unreadMessages[conversation._id] > 0
    );
    const readConversations = allConversations.filter(
      (conversation) => !unreadMessages[conversation._id]
    );

    // Sort by last message date
    const sortByLastMessageDate = (a: any, b: any) => {
      try {
        const lastMessageA = a.messages?.[a.messages.length - 1];
        const lastMessageB = b.messages?.[b.messages.length - 1];
        
        // If either conversation has no messages, handle it gracefully
        if (!lastMessageA?.date && !lastMessageB?.date) return 0;
        if (!lastMessageA?.date) return 1;  // b comes first
        if (!lastMessageB?.date) return -1; // a comes first
        
        const dateA = new Date(lastMessageA.date);
        const dateB = new Date(lastMessageB.date);
        
        // Check if dates are valid
        if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
        if (isNaN(dateA.getTime())) return 1;  // b comes first
        if (isNaN(dateB.getTime())) return -1; // a comes first
        
        return dateB.getTime() - dateA.getTime();
      } catch (error) {
        console.error("Error sorting conversations:", error);
        return 0;
      }
    };

    unreadConversations.sort(sortByLastMessageDate);
    readConversations.sort(sortByLastMessageDate);

    // Merge unread conversations first, then read ones
    const sortedConversations = [...unreadConversations, ...readConversations];
    return sortedConversations;
  };

  const EmptyConversationListSplash = () => (
    <div className={styles.emptySplashContainer}>
      <div className={styles.emptySplashContent}>
        <div className={styles.emptySplashGraphic}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4ZM2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24Z" fill="#5440F0" fillOpacity="0.2"/>
            <path d="M16 19H32" stroke="#5440F0" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M16 24H28" stroke="#5440F0" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M16 29H24" stroke="#5440F0" strokeWidth="1.5" strokeLinecap="round"/>
          </svg>
        </div>
        <div className={styles.emptySplashTextContent}>
          <h3 className={styles.emptySplashTitle}>Your inbox is empty</h3>
          <p className={styles.emptySplashText}>
            Your messages will appear here
          </p>
        </div>
      </div>
    </div>
  );

  const EmptyConversationSplash = () => (
    <div className={styles.emptySplashContainer}>
      <div className={styles.emptySplashContent}>
        <div className={styles.emptySplashGraphic}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="12" width="32" height="24" rx="3" stroke="#5440F0" strokeWidth="1.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8 15C8 13.3431 9.34315 12 11 12H37C38.6569 12 40 13.3431 40 15V18H8V15ZM8 20H40V33C40 34.6569 38.6569 36 37 36H11C9.34315 36 8 34.6569 8 33V20Z" fill="#5440F0" fillOpacity="0.1"/>
            <circle cx="12" cy="15" r="1" fill="#5440F0"/>
            <circle cx="16" cy="15" r="1" fill="#5440F0"/>
            <path d="M14 28H34" stroke="#5440F0" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14 24H30" stroke="#5440F0" strokeWidth="1.5" strokeLinecap="round"/>
          </svg>
        </div>
        <div className={styles.emptySplashTextContent}>
          <h3 className={styles.emptySplashTitle}>Start the conversation</h3>
          <p className={styles.emptySplashText}>
            Send a message to begin your discussion
          </p>
        </div>
      </div>
    </div>
  );

  // Extract existing conversation partner IDs
  const getExistingConversationPartnerIds = () => {
    return [...conversations, ...temporalConversations]
      .map(conv => user_id === conv.mentor?.user_id ? conv.mentee?.user_id : conv.mentor?.user_id)
      .filter(id => id); // Filter out undefined/null values
  };

  return (
    <>
      <Header>
        <div className={styles.headerContainer}>
          {isMobile && (
            <div className={styles.backButton} onClick={toggleSidebar}>
              <img src={BackArrow} alt="Back" />
            </div>
          )}
          <div>
            <h3 className={styles.convoTitle}>Messages</h3>
            {selectedConversation && type === "mentee" && (
              <h5
                className={styles.convoWithTitle}
                onClick={() => goToMentorProfile(selectedConversation.mentor?.slug)}
              >
                {" "}
                with{" "}
                {selectedConversation.mentor?.given_name}{" "}
                {selectedConversation.mentor?.family_name}
              </h5>
            )}
          </div>
        </div>
      </Header>
      <div className={styles.contentContainer}>
        {isSidebarVisible && (
          <div className={styles.sidebar}>
            {type === "mentor" && (
              <MessagingPartners 
                onSelectPartner={handlePartnerSelect} 
                existingConversationPartnerIds={getExistingConversationPartnerIds()}
              />
            )}
            {/* TODO: Implement proper filtering for Prospects/Mentees tabs
            {type === "mentor" && (
              <div className={styles.tabs}>
                <div
                  className={`${styles.tab} ${
                    activeTab === "prospects" ? styles.active : ""
                  }`}
                  onClick={() => setActiveTab("prospects")}
                >
                  Prospects
                </div>
                <div
                  className={`${styles.tab} ${
                    activeTab === "mentees" ? styles.active : ""
                  }`}
                  onClick={() => setActiveTab("mentees")}
                >
                  Mentees
                </div>
              </div>
            )}
            */}
            <div className={styles.conversationsList}>
              {!fetchedConversations ? (
                <ConversationListSkeleton />
              ) : (conversations?.length > 0 ||
                  temporalConversations?.length > 0) ? (
                getFilteredAndSortedConversations().map((conversation: any) => (
                  <div
                    key={conversation._id}
                    onClick={() => handleSelectConversation(conversation)}
                    onMouseEnter={() => setHoveredConversationId(conversation._id)}
                    onMouseLeave={() => setHoveredConversationId(null)}
                    className={`${styles.convoCard} ${
                      selectedConversation?._id === conversation._id ? styles.active : ''
                    } ${
                      hoveredConversationId === conversation._id ? styles.hovered : ''
                    }`}
                  >
                    <div className={styles.convoCardHeader}>
                      <img
                        src={
                          user_id !== conversation.mentor?.user_id
                            ? conversation.mentor?.profileImage?.url ||
                              AvatarPlaceholder
                            : conversation.mentee?.profileImage?.url ||
                              AvatarPlaceholder
                        }
                        alt="user-image"
                        className={styles.avatar}
                      />
                      <div>
                        <h6 className={styles.sender}>
                          {user_id !== conversation.mentor?.user_id
                            ? conversation.mentor?.given_name
                            : conversation.mentee?.given_name}{" "}
                          {user_id !== conversation.mentor?.user_id
                            ? conversation.mentor?.family_name
                            : conversation.mentee?.family_name}
                        </h6>
                        <p className={styles.senderMessage}>
                          {conversation.messages?.length > 0
                            ? conversation.messages[
                                conversation.messages.length - 1
                              ]?.message || "No messages yet"
                            : "No messages yet"}
                        </p>
                      </div>
                      <div>
                        {unreadMessages[conversation._id] > 0 && (
                          <span className={styles.unreadBadge}>New</span>
                        )}
                        <span className={styles.timestampBadge}>
                          {conversation.messages?.length > 0 && conversation.messages[conversation.messages.length - 1]?.date &&
                            formatDate(conversation.messages[conversation.messages.length - 1].date)
                          }
                        </span>
                        {(!conversation.messages || conversation.messages.length === 0) && (
                          <button
                            className={styles.deleteButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteConversation(conversation._id);
                            }}
                            title="Delete conversation"
                          >
                            ×
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyConversationListSplash />
              )}
            </div>
            <div className={styles.sidebarFooter}>
              <SegmentedProfileSwitcher />
              {type === "mentor" && (
                <div
                  className={styles.messagingSettings}
                  onClick={() => setIsModalOpen(true)}
                >
                  <img src={Settings} width={14} alt="Settings" />
                  <div className={styles.messagingSettingsText}>
                    Settings
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {selectedConversation && (!isMobile || !isSidebarVisible) && (
          <div className={styles.chatContainer} onClick={focusConversation}>
            {type === "mentor" && selectedConversation.mentee && (
              <RelationshipSummary 
                menteeId={selectedConversation.mentee.user_id} 
                mentorId={user_id}
              />
            )}
            <div className={styles.chatBox}>
              <div className={styles.messages}>
                {selectedConversation?.messages?.length > 0 ? (
                  groupMessages(selectedConversation.messages).map(
                    (group, index) => {
                      const isUserMessage = group[0].sender_id === user_id;
                      return (
                        <div
                          key={index}
                          className={`${styles.messageBlock} ${
                            isUserMessage ? styles.user : styles.other
                          }`}
                        >
                          {group.map((message: any, idx: number) => (
                            <div
                              key={idx}
                              className={`${styles.message} ${
                                isUserMessage ? styles.user : styles.other
                              }`}
                            >
                              {message.type === "text" && message.message}
                            </div>
                          ))}
                          <div className={styles.userInfo}>
                            <div className={styles.userNameInfo}>
                              {!isUserMessage && (
                                <img
                                  src={
                                    user_id !==
                                    selectedConversation.mentor?.user_id
                                      ? selectedConversation.mentor
                                          ?.profileImage?.url ||
                                        AvatarPlaceholder
                                      : selectedConversation.mentee
                                          ?.profileImage?.url ||
                                        AvatarPlaceholder
                                  }
                                  alt="user-image"
                                  className={styles.senderAvatar}
                                />
                              )}
                              <span className={styles.senderName}>
                                {group[group.length - 1]?.date ? 
                                  formatDate(group[group.length - 1].date) : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <EmptyConversationSplash />
                )}
                <div ref={messagesEndRef} />
              </div>
            </div>
            {shouldShowInput() ? (
              <div className={styles.inputWrapper}>
                {error && (
                  <div className={styles.errorNotification}>
                    <span>{error}</span>
                  </div>
                )}
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Compose a message..."
                    className={styles.inputBox}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && newMessage.trim() !== "") {
                        preSendMessage();
                      }
                    }}
                  />
                  <button
                    onClick={() => {
                      preSendMessage();
                    }}
                    className={styles.sendButton}
                  >
                    <img src={SendIcon} alt="Send" />
                  </button>
                </div>
                {renderMessagingLimitNotification()}
              </div>
            ) : (
              <div className={styles.lockedMessage} onClick={goToBooking}>
                Book a session to unlock messaging
              </div>
            )}
          </div>
        )}
      </div>
      <ConversationSettingsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        conversation={selectedConversation}
        saveSettings={saveSettings}
      />
    </>
  );
};

export default MyChats;
