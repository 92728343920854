import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const IntercomLoader = () => {
  const location = useLocation();

  useEffect(() => {
    const w = window as any;

    const loadIntercom = () => {

      w.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "yq8cmpr9",
      };

      if (!w.Intercom) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://widget.intercom.io/widget/yq8cmpr9";
        script.onload = () => {
          if (typeof w.Intercom === "function") {
            w.Intercom("boot", w.intercomSettings);
          } else {
            console.warn("Intercom failed to initialize.");
          }
        };
        document.head.appendChild(script);
      } else {
        w.Intercom("boot", w.intercomSettings);
      }
    };

    const unloadIntercom = () => {

      if (typeof w.Intercom === "function") {
        w.Intercom("shutdown");
      }

      // Remove the script to prevent reloading
      const script = document.querySelector(
        'script[src="https://widget.intercom.io/widget/yq8cmpr9"]'
      );
      if (script) {
        script.parentNode?.removeChild(script);
      }

      // Remove injected Intercom elements
      document.querySelectorAll("iframe[id^='intercom-frame']").forEach((el) => el.remove());
      document.querySelectorAll("div[id^='intercom']").forEach((el) => el.remove());
      document.querySelectorAll("style[data-intercom]").forEach((el) => el.remove());

      // Ensure the Intercom function is deleted
      delete w.Intercom;
    };

    if (!location.pathname.startsWith("/messaging")) {
      loadIntercom();
    } else {
      unloadIntercom();
    }

    return () => {
      unloadIntercom();
    };
  }, [location.pathname]);

  return null;
};

export default IntercomLoader;
