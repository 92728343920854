// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_API_URL as string;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;
export const createHeaders = (token?: string) => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Accept", "application/json");
	if (token) {
		headers.append("authorization", `Bearer ${token}`);
	}
	headers.append("Origin", origin);
	return headers;
};
