import React, { useState, useEffect } from "react";
import DropdownSelector from "components/Atoms/DropdownSelector";
import styles from "./ConversationSettingsModal.module.css";
import useMessaging from "hooks/useMessaging";

interface ConversationSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  conversation: any; // Adjust the type according to your data structure
  saveSettings: (settings: {
    type: string;
    isUnlimited: boolean;
    messages: number;
  }) => void;
}

const ConversationSettingsModal: React.FC<ConversationSettingsModalProps> = ({
  isOpen,
  onClose,
  saveSettings,
}) => {
  const { getMentorSettings } = useMessaging();
  const [whoCanMessage, setWhoCanMessage] = useState("Everyone");
  const [prospectMessages, setProspectMessages] = useState(2);
  const [isUnlimited, setIsUnlimited] = useState(true);

  useEffect(() => {
    const loadSettings = async () => {
      const settings = await getMentorSettings();
      setWhoCanMessage(settings.type || "Everyone");
      setIsUnlimited(settings.messages === 0);
      setProspectMessages(settings.messages || 2);
    };

    if (isOpen) {
      loadSettings();
    }
  }, [isOpen]);

  const options = [
    { value: "Everyone", label: "Everyone" },
    { value: "Mentees", label: "My mentees" },
    { value: "Nobody", label: "Nobody" },
  ];

  const unlimitedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleSave = () => {
    saveSettings({
      type: whoCanMessage,
      isUnlimited,
      messages: isUnlimited ? 0 : prospectMessages, // 0 means unlimited
    });
    onClose();
  };

  const capitalizeFirstLetter = (str?: string) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div>
          <button className={styles.closeButton} onClick={onClose} aria-label="Close panel">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4L4 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
              <path d="M4 4L12 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        <h3 className={styles.modalTitle}>Conversation Settings</h3>
        <div className={styles.setting}>
          <h4 className={styles.title}>Who can message me?</h4>
          <DropdownSelector
            id="ProfileSelector"
            key={"profile-selector"}
            value={{
              title: capitalizeFirstLetter(whoCanMessage),
              value: whoCanMessage,
            }}
            options={options.map((option) => ({
              title: capitalizeFirstLetter(option.value),
              value: option.value,
            }))}
            onSelect={(option) => setWhoCanMessage(option.value)}
            dark={true}
          />
        </div>

        {whoCanMessage === "Everyone" && (
          <>
            <div className={styles.setting}>
              <h4 className={styles.title}>Unlimited messages?</h4>
              <DropdownSelector
                id="UnlimitedMessagesSelector"
                key={"unlimited-messages-selector"}
                value={{
                  title: isUnlimited ? "Yes" : "No",
                  value: isUnlimited,
                }}
                options={unlimitedOptions.map((option) => ({
                  title: option.label,
                  value: option.value,
                }))}
                onSelect={(option) => setIsUnlimited(option.value)}
                dark={true}
              />
            </div>

            {!isUnlimited && (
              <div className={styles.setting}>
                <h4 className={styles.title}>
                  How many messages can prospects send me?
                </h4>
                <input
                  className={styles.messageInput}
                  type="number"
                  value={prospectMessages}
                  onChange={(e) => setProspectMessages(Number(e.target.value))}
                  min={1}
                />
              </div>
            )}
          </>
        )}

        <button className={styles.saveButton} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ConversationSettingsModal;
