import React from 'react';
import styles from './MinimalHeaderSkeleton.module.css';

const MinimalHeaderSkeleton: React.FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.profileSection}>
        <div className={styles.profileImageSkeleton}></div>
        <div className={styles.profileNameSkeleton}></div>
      </div>
      <div className={styles.infoButtonSkeleton}></div>
    </div>
  );
};

export default MinimalHeaderSkeleton; 