import React, { useState, useEffect } from 'react';
import useMentorSessions from 'hooks/mentor/useMentorSessions';
import AvatarPlaceholder from 'images/avatar-placeholder.gif';

// Import new components
import MinimalHeader from './MinimalHeader';
import MinimalHeaderSkeleton from './MinimalHeaderSkeleton';
import SidePanel from './SidePanel';
import ProfileSummary from './ProfileSummary';
import SessionCard from './SessionCard';

// Import styles for this component (only what's needed in this file)
import styles from './RelationshipSummary.module.css';

interface RelationshipSummaryProps {
  menteeId: string;
  mentorId: string;
}

const RelationshipSummary: React.FC<RelationshipSummaryProps> = ({ menteeId, mentorId }) => {
  const { sessions, is_loading } = useMentorSessions();
  const [relationshipSessions, setRelationshipSessions] = useState<any[]>([]);
  const [totalSessions, setTotalSessions] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [lastSessionDate, setLastSessionDate] = useState<string | null>(null);
  const [menteeProfile, setMenteeProfile] = useState<any>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  
  // Filter sessions for this specific relationship
  useEffect(() => {
    if (is_loading) return;

    // Combine all sessions
    const allSessions = [
      ...(sessions.upcoming || []),
      ...(sessions.past || []),
      ...(sessions.request || []),
    ];

    // Filter sessions for this specific mentee
    const filteredSessions = allSessions.filter(session => {
      return session.mentee_profile && session.mentee_profile.user_id === menteeId;
    });

    // Get mentee profile from the first session
    if (filteredSessions.length > 0 && filteredSessions[0].mentee_profile) {
      setMenteeProfile(filteredSessions[0].mentee_profile);
    }

    // Sort sessions by date (most recent first)
    const sortedSessions = filteredSessions.sort((a, b) => {
      const dateA = new Date(a.slot || a.ideal_proposed_time || a.createdAt);
      const dateB = new Date(b.slot || b.ideal_proposed_time || b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

    // Calculate total revenue
    const revenue = filteredSessions.reduce((total, session) => {
      // Only count completed sessions
      if (session.status === 'completed' && session.price) {
        return total + session.price;
      }
      return total;
    }, 0);

    // Set last session date
    const lastSession = sortedSessions.find(session => session.status === 'completed');
    const lastDate = lastSession ? (lastSession.slot || lastSession.ideal_proposed_time) : null;

    setRelationshipSessions(sortedSessions);
    setTotalSessions(filteredSessions.length);
    setTotalRevenue(revenue);
    setLastSessionDate(lastDate);
  }, [sessions, is_loading, menteeId]);

  const openPanel = () => {
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  if (is_loading) {
    return <MinimalHeaderSkeleton />;
  }

  if (relationshipSessions.length === 0) {
    return null; // Don't show the component if there are no sessions
  }

  // Get mentee name
  const menteeName = menteeProfile ? 
    `${menteeProfile.given_name || ''} ${menteeProfile.family_name || ''}`.trim() : 
    'Mentee';
  
  // Get mentee profile image
  const menteeImage = menteeProfile?.profileImage?.url || AvatarPlaceholder;

  return (
    <>
      {/* Minimal header that's always visible */}
      <MinimalHeader 
        name={menteeName}
        profileImage={menteeImage}
        onInfoClick={openPanel}
      />
      
      {/* Side panel with detailed information */}
      <SidePanel 
        isOpen={isPanelOpen} 
        onClose={closePanel}
        title="Relationship Summary"
      >
        {/* Profile summary section */}
        <ProfileSummary 
          profile={menteeProfile}
          totalSessions={totalSessions}
          totalRevenue={totalRevenue}
          lastSessionDate={lastSessionDate}
        />
        
        {/* Sessions section */}
        <div className={styles.sessionsSection}>
          <h3 className={styles.sectionTitle}>Sessions</h3>
          <div className={styles.sessionList}>
            {relationshipSessions.map((session, index) => (
              <SessionCard key={session._id || index} session={session} />
            ))}
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default RelationshipSummary; 