import React, { useRef, useEffect, useState } from "react";
import { PROFILE_TYPE } from "entities/Profile";
import useSwitchProfile from "hooks/user/useChangeProfile";
import useMyProfile from "hooks/useMyProfile";
import styles from "./SegmentedProfileSwitcher.module.css";

const SegmentedProfileSwitcher = () => {
  const { type } = useMyProfile();
  const { switchTo, profile_types } = useSwitchProfile();
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);

  // Filter out admin and keep only mentor and mentee profile types
  const filteredProfileTypes = profile_types?.filter(
    profileType => profileType === "mentor" || profileType === "mentee"
  ) || [];

  // Only show if user has both mentor and mentee profiles
  if (!filteredProfileTypes || filteredProfileTypes.length <= 1) {
    return null;
  }

  const capitalizeFirstLetter = (str?: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleProfileChange = (profileType: PROFILE_TYPE) => {
    if (profileType !== type) {
      switchTo(profileType);
    }
  };

  // Get the active index
  const activeIndex = filteredProfileTypes.findIndex(
    profileType => profileType === type
  );

  return (
    <div className={styles.switcherContainer} ref={containerRef}>
      <div className={styles.switcherTrack}>
        <div 
          className={styles.activeIndicator}
          style={{
            width: `${100 / filteredProfileTypes.length}%`,
            transform: `translateX(${activeIndex * 100}%)`
          }}
        />
        {filteredProfileTypes.map((profileType, index) => (
          <button
            key={profileType}
            ref={el => buttonsRef.current[index] = el}
            className={`${styles.switcherButton} ${type === profileType ? styles.active : ''}`}
            onClick={() => handleProfileChange(profileType)}
          >
            {capitalizeFirstLetter(profileType)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SegmentedProfileSwitcher; 