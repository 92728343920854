import React from 'react';
import styles from './ProfileSummary.module.css';
import AvatarPlaceholder from 'images/avatar-placeholder.gif';
import { format } from 'date-fns';

interface ProfileSummaryProps {
  profile: any;
  totalSessions: number;
  totalRevenue: number;
  lastSessionDate: string | null;
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  profile,
  totalSessions,
  totalRevenue,
  lastSessionDate,
}) => {
  const formatDate = (dateString: string | null): string => {
    if (!dateString) return 'Never';
    try {
      // Format as "Nov 30, 2022" format
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy');
    } catch (e) {
      return 'Date unavailable';
    }
  };

  const name = profile ? 
    `${profile.given_name || ''} ${profile.family_name || ''}`.trim() : 
    'User';
  
  const headline = profile?.headline || 'No headline provided';
  const profileImage = profile?.profileImage?.url || AvatarPlaceholder;
  
  const formattedRevenue = `$${Math.round(totalRevenue)}`;
  const formattedLastSessionDate = formatDate(lastSessionDate);

  return (
    <div className={styles.container}>
      <div className={styles.profileHeader}>
        <img 
          src={profileImage} 
          alt={name}
          className={styles.profileImage}
        />
        <div className={styles.profileInfo}>
          <h2 className={styles.name}>{name}</h2>
          <p className={styles.headline}>{headline}</p>
        </div>
      </div>
      
      <div className={styles.stats}>
        <div className={styles.statItem}>
          <div className={styles.statValue}>{totalSessions}</div>
          <div className={styles.statLabel}>Sessions</div>
        </div>
        
        <div className={styles.statItem}>
          <div className={styles.statValue}>{formattedRevenue}</div>
          <div className={styles.statLabel}>Revenue</div>
        </div>
        
        <div className={styles.statItem}>
          <div className={styles.statValue}>{formattedLastSessionDate}</div>
          <div className={styles.statLabel}>Last Session</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary; 