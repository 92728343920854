import Styles from "./CreditPackCheckout.module.css";
import OfferComponent from "./Components/Offer/Offer.component";
import OrderDetails from "./Components/OrderDetails/OrderDetails.component";
import CustomCreditPack from "./Components/CustomCreditPack/CustomCreditPack.component";
import PaymentInformation from "./Components/PaymentInformation";
import Pill from "components/Atoms/Pill";
import Button from "components/Atoms/Button";
import { ThreeDots } from "react-loader-spinner";
import {
  CreditPackContext,
  CreditPackState,
  OFFER_TITLE,
  OFFER_TRANSITION_EVENT,
  PaymentMethodResponse,
} from "./schema";
import SuccessCheckmark from "components/Atoms/SuccessCheckmark";
import RenewalDetails from "./Components/RenewalDetails";
import { isNumber } from "lodash";

const LoadingScreen = () => (
  <div className={Styles.loading}>
    <h4 style={{ textAlign: "center", marginBottom: "12px" }}>
      Loading credit packs
    </h4>
    <div className="center">
      <ThreeDots
        wrapperStyle={{ margin: "auto", display: "block" }}
        color="#fff"
        height={32}
        width={32}
      />
    </div>
  </div>
);

interface CreditPackProps {
  id: string;
  context: CreditPackContext;
  ui_state: CreditPackState;
  custom_offer_open: boolean;
  checkout: () => void;
  setCustomQuantity: (quantity: number) => void;
  toggleCustomOfferTab: () => void;
  isSelectedOffer: (offer_id: OFFER_TITLE) => boolean;
  updateBillingDetails: () => void;
  selectOffer: (offer_event: OFFER_TRANSITION_EVENT) => void;
  toggleCreditWindow: () => void;
}

const CreditPackCheckoutFragment = ({
  id,
  context,
  ui_state,
  custom_offer_open,
  toggleCustomOfferTab,
  setCustomQuantity,
  isSelectedOffer,
  selectOffer,
  updateBillingDetails,
  checkout,
  toggleCreditWindow,
}: CreditPackProps) => {
  const { offers } = context;

  const renderState = () => {
    if (ui_state === CreditPackState.CHECKOUT_COMPLETE) {
      const credits_checkout_total = new Intl.NumberFormat("en-US").format(
        context.credit_quantity
      );
      return (
        <div className={Styles.success_container}>
          <SuccessCheckmark dark={true} />
          <h3>SUCCESS!</h3>
          <p className={Styles.success_subtitle}>
            {credits_checkout_total} credits will appear in you account shortly.
          </p>
          <p className={Styles.success_information}>
            If you don’t see your credits please refresh your browser.
          </p>
        </div>
      );
    }
    if (ui_state === CreditPackState.LOADING_OFFERS) {
      return <LoadingScreen />;
    }
    if (
      [
        CreditPackState.OFFERS_READY,
        CreditPackState.PURCHASING,
        CreditPackState.PURCHASE_NOT_ALLOWED,
      ].includes(ui_state)
    ) {
      return (
        <>
          <div id={id} className={Styles.component_container}>
            <h2 className={Styles.title}>Purchase Credit Pack</h2>
            <RenewalDetails
              is_loading={!isNumber(context.days_until_sub_renews)}
              days_until_sub_renews={context.days_until_sub_renews}
            />
            <div style={{ marginTop: '8px', fontSize: '14px', color: '#ccc' }}>
              <a
                href={`${process.env.REACT_APP_ORIGIN_URL}/checkout`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ 
                  color: '#ccc', 
                  textDecoration: 'underline',
                  display: 'block'
                }}
              >
                Want to save on credits? Activate your MentorPass.
              </a>
            </div>
          </div>
          <div className={Styles.component_container}>
            <div className={Styles.offers}>
              <OfferComponent
                {...offers[0]}
                selected={isSelectedOffer(OFFER_TITLE.OFFER_1)}
                onSelect={() =>
                  selectOffer(OFFER_TRANSITION_EVENT.SELECT_OFFER_1)
                }
              />
              <OfferComponent
                {...offers[1]}
                selected={isSelectedOffer(OFFER_TITLE.OFFER_2)}
                onSelect={() =>
                  selectOffer(OFFER_TRANSITION_EVENT.SELECT_OFFER_2)
                }
              />
              <OfferComponent
                {...offers[2]}
                selected={isSelectedOffer(OFFER_TITLE.OFFER_3)}
                onSelect={() =>
                  selectOffer(OFFER_TRANSITION_EVENT.SELECT_OFFER_3)
                }
              />
            </div>
            {!custom_offer_open && (
              <Pill
                style={{
                  width: "100%",
                  marginTop: "12px",
                  borderRadius: "4px",
                  color: "#fff",
                }}
                onClick={toggleCustomOfferTab}
              >
                Custom credit amount?
              </Pill>
            )}
            {custom_offer_open && (
              <CustomCreditPack
                credit_quantity={context.custom_quantity}
                cost_per_credit={context.rate}
                is_selected={isSelectedOffer(OFFER_TITLE.CUSTOM_OFFER)}
                setSelected={() =>
                  selectOffer(OFFER_TRANSITION_EVENT.SELECT_OFFER_CUSTOM)
                }
                updateCreditQuantity={setCustomQuantity}
              />
            )}
          </div>
          <div className={Styles.component_container}>
            <OrderDetails
              quantity_of_credits={context.credit_quantity}
              rate={context.rate}
            />
          </div>
          <div className={Styles.component_container}>
            {!!context?.payment_method && (
              <PaymentInformation
                {...context.payment_method}
                updateBillingDetails={updateBillingDetails}
              />
            )}
          </div>
          <div>
            <Button
              variant="primary"
              disabled={ui_state === CreditPackState.PURCHASING}
              loading={ui_state === CreditPackState.PURCHASING}
              className={Styles.checkout}
              onClick={checkout}
            >
              Confirm & Pay
            </Button>
            <Button
              variant="dark"
              className={Styles.close_button}
              onClick={toggleCreditWindow}
            >
              Close
            </Button>
          </div>
          <div>
            <p>*Credits expire after 12 months.</p>
          </div>
        </>
      );
    }
  };

  return (
    <div data-testid="CreditPackCheckout" className={Styles.container}>
      {ui_state === CreditPackState.PURCHASE_NOT_ALLOWED && (
        <>
          <p className={Styles.blackout_overlay_text}>
            You're not authorized to purchase credits for this account; please
            contact your account admin.
          </p>
          <div className={Styles.blackout_overlay}></div>
        </>
      )}
      {renderState()}
    </div>
  );
};

export default CreditPackCheckoutFragment;
