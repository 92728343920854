import React, { useState, useEffect, useRef } from "react";
import styles from "./MessagingPartners.module.css";
import Avatar from "components/Atoms/Avatar/Avatar.component";
import AvatarPlaceholder from "../../../../images/avatar-placeholder.gif";
import useMentorSessions from "hooks/mentor/useMentorSessions";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";
import useMyProfile from "hooks/useMyProfile";
import { PROFILE_TYPE } from "entities/Profile";
import MessagingPartnersSkeleton from "./MessagingPartnersSkeleton";

interface MessagingPartnersProps {
  onSelectPartner: (partner: any) => void;
  existingConversationPartnerIds?: string[]; // IDs of partners already in conversations
}

// Define interfaces for the session data structure
interface ProfileImage {
  url: string | null;
}

interface MenteeProfile {
  user_id: string;
  given_name?: string;
  family_name?: string;
  profileImage?: ProfileImage;
  type?: string;
}

interface SessionData {
  from?: MenteeProfile | string; // Can be either MenteeProfile or string
  mentee_profile?: MenteeProfile;
  mentee?: MenteeProfile;
  mentee_id?: string;
  mentee_name?: string;
  mentee_image?: string;
  slot?: string;
  date?: string;
  created_at?: string;
  updatedAt?: string;
  createdAt?: string;
}

interface PartnerData {
  user_id: string;
  given_name: string;
  family_name: string;
  profileImage?: ProfileImage;
  session_date: Date;
}

const MessagingPartners: React.FC<MessagingPartnersProps> = ({ 
  onSelectPartner, 
  existingConversationPartnerIds = [] 
}) => {
  const { user } = useAuth0();
  const { user_id } = processAuthClaims(user);
  const { type } = useMyProfile();
  const { sessions, is_loading } = useMentorSessions();
  const [partners, set_partners] = useState<PartnerData[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // Check if scrolling is needed and update arrow visibility
  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      
      // Show left arrow if scrolled to the right
      setShowLeftArrow(scrollLeft > 0);
      
      // Show right arrow if there's more content to scroll to
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 10); // 10px buffer
    }
  };

  // Handle scroll events
  const handleScroll = () => {
    checkScrollPosition();
  };

  // Scroll left or right
  const handleScrollClick = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust scroll amount as needed
      const currentScroll = scrollContainerRef.current.scrollLeft;
      
      scrollContainerRef.current.scrollTo({
        left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  // Check scroll position when partners change
  useEffect(() => {
    if (partners.length > 0) {
      // Use setTimeout to ensure the DOM has updated
      setTimeout(checkScrollPosition, 100);
    }
  }, [partners]);

  // Add window resize listener to check scroll position
  useEffect(() => {
    const handleResize = () => {
      checkScrollPosition();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Helper function to get the most recent date from a session
  const getSessionDate = (session: SessionData): Date => {
    // Try to get the most meaningful date from the session
    const dateStr = 
      session.slot || 
      session.date || 
      session.created_at || 
      session.updatedAt || 
      session.createdAt || 
      new Date().toISOString();
    
    try {
      return new Date(dateStr);
    } catch (e) {
      console.error('Invalid date format:', dateStr);
      return new Date(0); // Default to epoch time if date is invalid
    }
  };

  useEffect(() => {
    if (is_loading) {
      return;
    }
    
    // Combine all sessions (upcoming, past, request)
    const all_sessions = [
      ...(sessions.upcoming || []),
      ...(sessions.past || []),
      ...(sessions.request || []),
    ];
    
    // Extract unique mentees from sessions with their most recent session date
    const mentee_map = new Map<string, PartnerData>(); // Use a map to track the most recent session for each mentee
    
    all_sessions.forEach((session: any) => {
      try {
        // Try to extract mentee information from different possible locations in the session object
        let mentee: PartnerData | null = null;
        const session_date = getSessionDate(session);
        
        // Option 1: session.from (based on SessionQueryResultWithProfiles)
        if (session.from && typeof session.from === 'object' && session.from.user_id && session.from.type === 'mentee') {
          mentee = {
            user_id: session.from.user_id,
            given_name: session.from.given_name || '',
            family_name: session.from.family_name || '',
            profileImage: session.from.profileImage,
            session_date
          };
        } 
        // Option 2: session.mentee_profile (based on MentorSessionQueryResult)
        else if (session.mentee_profile && session.mentee_profile.user_id) {
          mentee = {
            user_id: session.mentee_profile.user_id,
            given_name: session.mentee_profile.given_name || '',
            family_name: session.mentee_profile.family_name || '',
            profileImage: session.mentee_profile.profileImage,
            session_date
          };
        }
        // Option 3: session.mentee (custom property)
        else if (session.mentee && session.mentee.user_id) {
          mentee = {
            user_id: session.mentee.user_id,
            given_name: session.mentee.given_name || '',
            family_name: session.mentee.family_name || '',
            profileImage: session.mentee.profileImage,
            session_date
          };
        }
        // Option 4: Fallback to mentee_id and mentee_name if available
        else if (session.mentee_id) {
          mentee = {
            user_id: session.mentee_id,
            given_name: session.mentee_name ? session.mentee_name.split(' ')[0] : '',
            family_name: session.mentee_name ? session.mentee_name.split(' ').slice(1).join(' ') : '',
            profileImage: session.mentee_image ? { url: session.mentee_image } : undefined,
            session_date
          };
        }
        
        // If we couldn't extract mentee information, skip this session
        if (!mentee || !mentee.user_id) {
          return;
        }
        
        // Skip if mentee is already in conversations
        if (existingConversationPartnerIds.includes(mentee.user_id)) {
          return;
        }
        
        // Check if we already have this mentee in our map
        if (mentee_map.has(mentee.user_id)) {
          const existing_mentee = mentee_map.get(mentee.user_id);
          // Only update if this session is more recent
          if (existing_mentee && mentee.session_date > existing_mentee.session_date) {
            mentee_map.set(mentee.user_id, mentee);
          }
        } else {
          // Add new mentee to map
          mentee_map.set(mentee.user_id, mentee);
        }
      } catch (error) {
        console.error('Error processing session:', error);
      }
    });
    
    // Convert map to array
    const unique_partners = Array.from(mentee_map.values());
    
    // Split partners into two groups: with and without profile images
    const partners_with_images = unique_partners.filter(partner => partner.profileImage?.url);
    const partners_without_images = unique_partners.filter(partner => !partner.profileImage?.url);
    
    // Sort each group by most recent session date (descending)
    const sorted_partners_with_images = partners_with_images.sort((a, b) => 
      b.session_date.getTime() - a.session_date.getTime()
    );
    
    const sorted_partners_without_images = partners_without_images.sort((a, b) => 
      b.session_date.getTime() - a.session_date.getTime()
    );
    
    // Combine the two sorted groups, with partners with images first
    const sorted_partners = [...sorted_partners_with_images, ...sorted_partners_without_images];
    
    set_partners(sorted_partners);
  }, [sessions, is_loading, existingConversationPartnerIds]);

  // Function to get initials from name
  const get_initials = (given_name: string = "", family_name: string = "") => {
    const first_initial = given_name ? given_name.charAt(0).toUpperCase() : "";
    const last_initial = family_name ? family_name.charAt(0).toUpperCase() : "";
    return first_initial || last_initial ? `${first_initial}${last_initial}` : "?";
  };

  // Function to get first name with fallback
  const get_first_name = (partner: PartnerData) => {
    return partner.given_name || "User";
  };

  const handlePartnerClick = (partner: PartnerData) => {
    // Determine if current user is mentor or mentee
    const isCurrentUserMentor = type === PROFILE_TYPE.MENTOR;

    // Create the partner object with the correct user IDs and source
    const partnerWithIds = {
      ...partner,
      mentor_user_id: isCurrentUserMentor ? user_id : partner.user_id,
      mentee_user_id: isCurrentUserMentor ? partner.user_id : user_id,
      source: "messaging_partners" // Add source information for analytics
    };

    onSelectPartner(partnerWithIds);
  };

  if (is_loading) {
    return <MessagingPartnersSkeleton />;
  }

  if (partners.length === 0) {
    return null;
  }

  // Get scroll container class names with fade effects
  const scrollContainerClasses = [
    styles.scrollContainer,
    showLeftArrow ? styles.showLeftFade : '',
    showRightArrow ? styles.showRightFade : ''
  ].filter(Boolean).join(' ');

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h6 className={styles.title}>Message Your {type === PROFILE_TYPE.MENTOR ? "Mentees" : "Mentors"}</h6>
      </div>
      <div className={styles.scrollableContainer}>
        {showLeftArrow && (
          <button 
            className={`${styles.scrollArrow} ${styles.leftArrow}`}
            onClick={() => handleScrollClick('left')}
            aria-label="Scroll left"
          >
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 12L6 8L10 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        )}
        
        <div 
          className={scrollContainerClasses}
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <div className={styles.avatarList}>
            {partners.map((partner) => (
              <div 
                key={partner.user_id}
                className={styles.avatarWrapper}
                onClick={() => handlePartnerClick(partner)}
              >
                {partner.profileImage?.url ? (
                  <Avatar
                    size={48}
                    src={partner.profileImage.url}
                    name={get_first_name(partner)}
                  />
                ) : (
                  <div className={styles.initialsAvatar}>
                    {get_initials(partner.given_name, partner.family_name)}
                  </div>
                )}
                <div className={styles.partnerName}>
                  {get_first_name(partner)}
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {showRightArrow && (
          <button 
            className={`${styles.scrollArrow} ${styles.rightArrow}`}
            onClick={() => handleScrollClick('right')}
            aria-label="Scroll right"
          >
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 4L10 8L6 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default MessagingPartners; 