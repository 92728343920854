import { useState, useEffect, useCallback } from 'react';
import useMessaging from 'hooks/useMessaging';

const useMessagingSettings = (userId: string) => {
  const { getMentorSettingsById } = useMessaging();
  const [messagingDisabled, setMessagingDisabled] = useState(false);
  const [isCheckingSettings, setIsCheckingSettings] = useState(false);

  const checkMessagingSettings = useCallback(async () => {
    if (!userId) return;
    setIsCheckingSettings(true);
    try {
      const settings = await getMentorSettingsById(userId);
      setMessagingDisabled(settings?.type === "Nobody");
    } catch (error) {
      // Log error without exposing user data
      console.error('Error checking messaging settings');
      setMessagingDisabled(false);
    } finally {
      setIsCheckingSettings(false);
    }
  }, [userId, getMentorSettingsById]);

  useEffect(() => {
    checkMessagingSettings();
  }, [checkMessagingSettings]);

  return { messagingDisabled, isCheckingSettings };
};

export default useMessagingSettings;