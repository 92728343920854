import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./styles/app.css";
import "./styles/mobile.css";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Login from "./screens/Auth/Login";
import Dashboard from "./screens/Home";
import {
  transitions,
  positions,
  Provider as AlertProvider,
} from "@blaumaus/react-alert";
import AlertTemplate from "./components/Molecules/AlertTemplate";
import ErrorScreen from "./screens/ErrorScreen";
import AlertCreator from "./components/Organisms/AlertCreator";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import MentorSignup from "./screens/Auth/MentorSignup";
import MenteeSignup from "./screens/Auth/MenteeSignup";
import Checkout from "./screens/Checkout/MentorPass";
import getAuthVariables from "./utils/getAuthVariables";
import Preference from "screens/Utilities/Preference";
import AnalyticsWrapper from "./services/AnalyticsWrapper";
// Register Icon
import createIcons from "./utils/createIcons";
import OnePageCheckoutSignup from "screens/Auth/OnePageCheckout";
import { ModalProvider } from "components/Organisms/modals/ModalProvider";
import MentorPlusCheckout from "screens/Checkout/MentorPlusCheckout";
import { LiveSocketProvider } from "components/Organisms/Realtime/LiveSocket.provider";
import IntercomLoader from "./IntercomLoader";
createIcons();

const alertOptions = {
  position: positions.TOP_LEFT,
  timeout: 5000,
  offset: "32px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 500,
  },
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const { DOMAIN, CLIENT_ID, AUDIENCE, REDIRECT_URL } = getAuthVariables();

  const queryClient = new QueryClient();

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      authorizationParams={{
        redirect_uri: REDIRECT_URL,
        audience: AUDIENCE,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <LiveSocketProvider>
        <QueryClientProvider client={queryClient}>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <AnalyticsWrapper>
              <AlertCreator />
              <ModalProvider>
                <Routes>
                  <Route path="/set/:preference" element={<Preference />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<MenteeSignup />} />
                  <Route path="/signup-mentor" element={<MentorSignup />} />
                  <Route
                    path="/one_page_checkout"
                    element={<OnePageCheckoutSignup />}
                  />
                  <Route path="/error" element={<ErrorScreen />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route
                    path="/checkout_plus"
                    element={<MentorPlusCheckout />}
                  />
                  <Route path="/*" element={<Dashboard />} />
                </Routes>
              </ModalProvider>
            </AnalyticsWrapper>
          </AlertProvider>
          {location.pathname !== "/messaging" && (
            <>
              <ReactQueryDevtools initialIsOpen={false} />
              <IntercomLoader />
            </>
          )}
        </QueryClientProvider>
      </LiveSocketProvider>
    </Auth0Provider>
  );
};

export default App;
