import React, { useContext } from "react";
import styles from "./Header.module.css";
import { MobileNavOpen } from "../../Icons";
import CreditAvatar from "./CreditAvatar";
import { useLocation } from "react-router-dom";

type HeaderProps = {
  children: React.ReactNode;
  toggleMobileMenu: () => void;
  isMentee: boolean;
};

const Header = ({ children, toggleMobileMenu, isMentee }: HeaderProps) => {

  // REFACTOR
  // Header is an Organism it should be able to use the GlobalServiceContext
  // to identify if the user is a mentee or not.
  // and have the ability to toggle the mobile menu.
  const location = useLocation();

  return (
    <div data-testid="Header" className={styles.container}>
      <div className={styles.content}>{children}</div>
      <img
        className={styles.openMobileNav}
        src={MobileNavOpen}
        onClick={toggleMobileMenu}
      />
      {isMentee && location.pathname !== "/messaging" && (
        <div className={styles.creditAvatar}>
          <CreditAvatar />
        </div>
      )}
    </div>
  );
};

export default Header;
