import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";
import TimeSlotSelector from "../../Molecules/TimeSlotSelector/TimeSlotSelector";
import useMyProfile from "hooks/useMyProfile";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import { ALLOWED_STATUS } from "entities/Session";
import SendMessageButton from "components/Molecules/SendMessageButton/SendMessageButton";
import { useAuth0 } from "@auth0/auth0-react";
import { PROFILE_TYPE } from "entities/Profile";

interface RequestedSessionProps {
  session: MentorSessionQueryResult;
}

const RequestedSession = (props: RequestedSessionProps) => {
  const { accept, is_loading: accept_session_loading } = useAcceptSession();
  const { decline, is_loading: decline_session_loading } = useDeclineSession();
  const { profile, type } = useMyProfile();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const handleAcceptSession = async (slot: string): Promise<void> => {
    accept(props.session._id, slot, () => {
      window.location.reload();
    });
  };

  const handleDeclineSession = async () => {
    decline(props.session._id, "", () => {
      window.location.reload();
    });
  };

  const handleRescheduleSession = async () => {
    try {
      posthog.capture("mentor_clicked_reschedule_session", {
        status: props.session.status,
        session_id: props.session._id,
      });
    } catch (error) {
      console.error(error);
    }
    navigate(`/reschedule/${props.session._id}`);
  };

  // Get the current user's user_id
  const current_user_id = user ? user["https://api.mentorpass.co/user_id"] as string : "";
  
  // Set mentor and mentee user IDs correctly
  // Since we're in the mentor view, the current user is the mentor
  // and the mentee is from the session data
  const mentor_user_id = current_user_id;
  const mentee_user_id = props.session.mentee_profile.user_id;

  return (
    <MentorSessionCard
      session={props.session}
      actions={
        <div className={styles.requested_session_action_container}>
          {props.session.status === ALLOWED_STATUS.RESCHEDULE &&
          props.session.proposed_by !== "mentee" ? (
            <div>
              <div className={styles.rescheduled_message}>
                <p>Waiting on a response from your mentee</p>
                <div className={styles.proposed_times}>
                  <h4>Your proposed times:</h4>
                  <ul>
                    {props.session.proposed_times.map((time, index) => (
                      <li key={index}>
                        {new Date(time).toLocaleString(undefined, {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          timeZoneName: "short",
                        })}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={styles.message_button_container}>
                <SendMessageButton 
                  mentor_user_id={mentor_user_id}
                  mentee_user_id={mentee_user_id}
                  className={styles.message_button}
                />
              </div>
            </div>
          ) : (
            <div>
              <TimeSlotSelector
                timezone={profile.timezone}
                inital_slot={props.session.ideal_proposed_time}
                slots={props.session.proposed_times}
                note={props.session.reschedule_note}
                accept_session_loading={accept_session_loading}
                confirmSession={handleAcceptSession}
                decline_session_loading={decline_session_loading}
                handleRescheduleSession={handleRescheduleSession}
                handleDeclineSession={handleDeclineSession}
              />
              <div className={styles.message_button_container}>
                <SendMessageButton 
                  mentor_user_id={mentor_user_id}
                  mentee_user_id={mentee_user_id}
                  className={styles.message_button}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default RequestedSession;
