import { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { Account } from "./account";
import {
  BrowseMentors,
  BookSession,
  BookSessionSuccess,
  SessionReview,
  MySessions,
  MyChats,
  MissedSession,
} from "./mentee";
import { MentorDashboard, AcceptSession, DeclineSession } from "./mentor";
import { GoogleAuth, VideoBrowser } from "./both";
import TeamManagementScreen from "./mentee/TeamManagement/TeamManagment.screen";
import VideoPlayerScreen from "./both/VideoPlayer/VideoPlayer.screen";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useAppDispatch } from "hooks/redux";
import CreateProfileScreen from "../Auth/CreateProfile/CreateProfile.screen";
import { ProfileStateProvider } from "services/GlobalServiceProvider";
import useMyProfile from "hooks/useMyProfile";
import moment from "moment";
import { API_URL, createHeaders } from "utils/api";
import processAuthClaims from "utils/processAuthClaims";
import useUpdateUserMetadata from "hooks/user/useUpdateUserMetadata";
import Objectives from "./mentee/Objectives/Objectives.screen";
import Onboarding from "./mentee/Onboarding";
import Paywall from "components/Organisms/Paywall";
import ModalContainer from "components/Organisms/modals/ModalContainer";
import SideMenu from "components/Organisms/SideMenu";
import Profile from "./both/Profile";
import { PROFILE_TYPE } from "entities/Profile";
import AdminRoutes from "./admin";
import {
  mobile_menu_machine,
  MenuContext,
} from "../../components/Organisms/SideMenu/MobileMenu.machine";
import { useInterpret } from "@xstate-ninja/react";
import { NavigateEvent } from "components/Organisms/SideMenu/types";
import CommunityScreen from "./both/Community";
import FreemiumOnboardingScreen from "./mentee/FreemiumOnboarding/FreemiumOnboarding.screen";
import UserNotification from "components/Organisms/UserNotification";
import {
  ModalNames,
  useModal,
} from "components/Organisms/modals/ModalProvider";
import RescheduleSessionScreen from "./both/Reschedule";
const GlobalDashboard = () => {
  const { type } = useMyProfile();
  return (
    <>
      {type === PROFILE_TYPE.MENTOR ? <MentorDashboard /> : <BrowseMentors />}
    </>
  );
};

const Dashboard = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { update: updateUserMetadata } = useUpdateUserMetadata({});
  const navigate = useNavigate();
  const { openModal } = useModal();
  const user_data = processAuthClaims(user);

  const dispatchAddHeaderNotification = (data: {
    browser_tz: string;
    profile_tz: string;
  }) => openModal({ name: ModalNames.UPDATE_TIMEZONE_ALERT, data });

  const updateTimzone = (timezone: string) => updateUserMetadata({ timezone });

  const [search_params] = useSearchParams();

  useEffect(() => {
    const confirmConnected = async () => {
      const token = await getAccessTokenSilently();
      fetch(`${API_URL}/stripe/confirm-connected`, {
        method: "POST",
        mode: "cors",
        headers: createHeaders(token),
      });
    };

    const checkTimezone = () => {
      const guess = moment.tz.guess();
      if (!user_data.timezone) {
        updateTimzone(guess);
      }
      if (user_data.timezone && user_data.timezone !== guess) {
        dispatchAddHeaderNotification({
          browser_tz: guess,
          profile_tz: user_data.timezone,
        });
      }
    };

    // stripe
    const action = search_params.get("modal");
    if (action === "stripe-connected") {
      confirmConnected();
    }
    checkTimezone();
  }, []);

  const menu_service = useInterpret(mobile_menu_machine, {
    devTools: true,
    actions: {
      navigateTo: (_, event: NavigateEvent) => navigate(event.data.path),
    },
  });

  return (
    <ProfileStateProvider>
      <MenuContext.Provider
        value={{
          menu_service,
        }}
      >
        <Routes>
          <Route path="/start/*" element={<Onboarding />} />
          <Route
            path="/onboarding/mentee"
            element={<FreemiumOnboardingScreen />}
          />
          <Route
            path="/*"
            element={
              <div>
                <SideMenu />
                <div className="master-container">
                  <Routes>
                    <Route
                      path="/loading_profile"
                      element={<CreateProfileScreen />}
                    />
                    <Route path={"/dashboard"} element={<MentorDashboard />} />
                    <Route path={"/objectives"} element={<Objectives />} />
                    <Route path={"/my-sessions"} element={<MySessions />} />
                    <Route path={"/messaging"} element={<MyChats />} />
                    <Route path={"/video"} element={<VideoBrowser />} />
                    <Route
                      path={"/video_player/:recording_id"}
                      element={<VideoPlayerScreen />}
                    />
                    <Route path={"/account"} element={<Account />} />
                    <Route
                      path="/session-declined/:session_id"
                      element={<DeclineSession />}
                    />
                    <Route
                      path="/session-accepted/:session_id/:slot"
                      element={<AcceptSession />}
                    />
                    <Route
                      path="/session-accepted/:session_id"
                      element={<AcceptSession />}
                    />
                    <Route
                      path="/session-review/:session_id"
                      element={<SessionReview />}
                    />
                    <Route
                      path="/missed-session/:session_id"
                      element={<MissedSession />}
                    />
                    <Route
                      path={"/profile/:profile_slug"}
                      element={<Profile />}
                    />
                    <Route
                      path="/profile/:slug/book-session"
                      element={<BookSession />}
                    />
                    <Route
                      path="/profile/:slug/book-session/success"
                      element={<BookSessionSuccess />}
                    />
                    <Route
                      path="/reschedule/:session_id"
                      element={<RescheduleSessionScreen />}
                    />
                    <Route path="/team" element={<TeamManagementScreen />} />
                    <Route path="/" element={<GlobalDashboard />} />
                    <Route path="/google-auth" element={<GoogleAuth />} />
                    <Route path="/admin/*" element={<AdminRoutes />} />
                    <Route path="/community" element={<CommunityScreen />} />
                  </Routes>
                </div>
                <Paywall />
                <ModalContainer />
                <UserNotification />
                {/* <ProfileLoading />
                <ErrorContainer /> */}
              </div>
            }
          />
        </Routes>
      </MenuContext.Provider>
    </ProfileStateProvider>
  );
};

export default withAuthenticationRequired(Dashboard);
