import { usePostHog } from 'posthog-js/react';

// Helper function to determine device type
const getDeviceType = (): 'mobile' | 'desktop' => {
  return window.innerWidth <= 768 ? 'mobile' : 'desktop';
};

// Analytics service for messaging feature
export const useMessagingAnalytics = () => {
  const posthog = usePostHog();

  // Track conversation creation
  const trackConversationCreated = (
    conversationId: string, 
    otherUserId: string, 
    isCurrentUserMentor: boolean,
    source: string = 'unknown'
  ) => {
    if (!posthog) return;
    
    posthog.capture('conversation_created', {
      conversation_id: conversationId,
      with_user_id: otherUserId,
      user_role: isCurrentUserMentor ? 'mentor' : 'mentee',
      source: source,
      device_type: getDeviceType()
    });
  };

  // Track message sent
  const trackMessageSent = (
    conversationId: string,
    messageText: string,
    senderId: string,
    isFirstMessage: boolean,
    messageCount: number,
    temporal: boolean = false
  ) => {
    if (!posthog) return;
    
    posthog.capture('message_sent', {
      conversation_id: conversationId,
      message_length: messageText.length,
      sender_id: senderId,
      is_first_message: isFirstMessage,
      device_type: getDeviceType(),
      message_index: messageCount,
      temporal: temporal
    });
  };

  // Track messages read
  const trackMessagesRead = (
    conversationId: string,
    unreadCount: number,
    timeSinceLastMessage: number | null
  ) => {
    if (!posthog) return;
    
    posthog.capture('messages_read', {
      conversation_id: conversationId,
      unread_count: unreadCount,
      time_since_last_message: timeSinceLastMessage,
      device_type: getDeviceType()
    });
  };

  // Track conversation settings updated
  const trackConversationSettingsUpdated = (
    previousSettings: any,
    newSettings: any
  ) => {
    if (!posthog) return;
    
    posthog.capture('conversation_settings_updated', {
      previous_type: previousSettings.type,
      new_type: newSettings.type,
      previous_message_limit: previousSettings.messages,
      new_message_limit: newSettings.messages,
      previous_is_unlimited: previousSettings.isUnlimited,
      new_is_unlimited: newSettings.isUnlimited,
      device_type: getDeviceType()
    });
  };

  // Track messaging errors
  const trackMessagingError = (
    errorType: string,
    errorMessage: string,
    functionName: string,
    conversationId?: string
  ) => {
    if (!posthog) return;
    
    posthog.capture('messaging_error', {
      error_type: errorType,
      error_message: errorMessage,
      function: functionName,
      conversation_id: conversationId,
      device_type: getDeviceType()
    });
  };

  return {
    trackConversationCreated,
    trackMessageSent,
    trackMessagesRead,
    trackConversationSettingsUpdated,
    trackMessagingError
  };
}; 