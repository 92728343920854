import classNames from "classnames/bind";

import { IMenuItem } from "../types";

import styles from "./SideMenu.module.css";

interface IMenuItemProps extends IMenuItem {
  active: boolean;
  onClick: () => void;
  hasUnreadMessages?: boolean;
}

const cx = classNames.bind(styles);

const MenuItem: React.FC<IMenuItemProps> = ({
  icon,
  activeIcon,
  text,
  active,
  onClick,
  hasUnreadMessages = false,
}) => {
  const menuItemContainerStyles = cx({
    menuItemContainer: true,
    menuItemActive: active,
  });

  return (
    <div className={menuItemContainerStyles} onClick={onClick}>
      <div className={styles.iconContainer}>
        {active ? (
          <>
            <img src={activeIcon} alt="activeIcon" />
            {hasUnreadMessages && <div className={styles.unreadDot}></div>}
          </>
        ) : (
          <>
            <img src={icon} className={styles.menuIcon} alt="icon" />
            <img
              src={activeIcon}
              className={styles.menuIconActive}
              alt="activeIcon"
            />
            {hasUnreadMessages && <div className={styles.unreadDot}></div>}
          </>
        )}
      </div>
      <p className={styles.menuLinkText}>{text}</p>
    </div>
  );
};

export default MenuItem;
