import React from 'react';
import styles from './MessagingPartnersSkeleton.module.css';

const MessagingPartnersSkeleton: React.FC = () => {
  // Generate multiple avatar placeholders
  const avatarPlaceholderCount = 8;
  
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.titleSkeleton}></div>
      </div>
      <div className={styles.scrollableContainer}>
        <div className={styles.scrollContainer}>
          <div className={styles.avatarList}>
            {Array.from({ length: avatarPlaceholderCount }).map((_, index) => (
              <div key={index} className={styles.avatarWrapper}>
                <div className={styles.avatarSkeleton}></div>
                <div className={styles.nameSkeleton}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagingPartnersSkeleton; 