const MARK_MESSAGES_AS_READ = "MARK_MESSAGES_AS_READ";
const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
const UPDATE_UNREAD_MESSAGES = "UPDATE_UNREAD_MESSAGES";

const initialState = {
  unreadMessages: {},
};

const messagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case MARK_MESSAGES_AS_READ:
      const { conversationId } = action.payload;
      const { [conversationId]: _, ...remainingUnreadMessages } = state.unreadMessages;
      return {
        ...state,
        unreadMessages: remainingUnreadMessages,
      };
    case UPDATE_UNREAD_MESSAGES:
      const { conversation_id } = action.payload;
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          [conversation_id]: (state.unreadMessages[conversation_id] || 0) + 1,
        },
      };
    default:
      return state;
  }
};

export default messagingReducer;