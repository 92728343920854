import React from 'react';
import styles from './MinimalHeader.module.css';
import AvatarPlaceholder from 'images/avatar-placeholder.gif';
import InfoIcon from './InfoIcon';

interface MinimalHeaderProps {
  profileImage?: string;
  name: string;
  onInfoClick: () => void;
}

const MinimalHeader: React.FC<MinimalHeaderProps> = ({
  profileImage,
  name,
  onInfoClick,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.profileSection}>
        <img 
          src={profileImage || AvatarPlaceholder} 
          alt={name}
          className={styles.profileImage}
        />
        <h3 className={styles.profileName}>{name}</h3>
      </div>
      <button 
        className={styles.infoButton}
        onClick={onInfoClick}
        aria-label="View relationship details"
      >
        <InfoIcon />
      </button>
    </div>
  );
};

export default MinimalHeader; 