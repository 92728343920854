import React from 'react';
import { format } from 'date-fns';
import styles from './SessionModal.module.css';

interface SessionModalProps {
  session: any;
  onClose: () => void;
}

const SessionModal: React.FC<SessionModalProps> = ({ session, onClose }) => {
  // Format date
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return format(date, 'MMMM d, yyyy • h:mm a');
    } catch (e) {
      return 'Date unavailable';
    }
  };

  // Get status color
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return '#15B881'; // green
      case 'upcoming':
        return '#58a6ff'; // blue
      case 'request':
        return '#FBC95C'; // yellow
      case 'canceled':
      case 'declined':
        return '#FB836F'; // red
      default:
        return '#6e7891'; // gray
    }
  };

  // Format status text
  const formatStatus = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const formattedDate = formatDate(session.slot || session.ideal_proposed_time);
  const statusColor = getStatusColor(session.status);
  const statusText = formatStatus(session.status);
  const price = session.price ? `$${Math.round(session.price)}` : 'Free';
  const hasReview = session.mentee_review && session.mentee_review.nps;

  // Handle click on overlay to close
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h2 className={styles.title}>Session Details</h2>
          <button className={styles.closeButton} onClick={onClose} aria-label="Close modal">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4L4 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
              <path d="M4 4L12 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>
          </button>
        </div>

        <div className={styles.content}>
          <div className={styles.infoSection}>
            <div className={styles.dateStatus}>
              <div className={styles.statusBadge} style={{ backgroundColor: statusColor }}>
                {statusText}
              </div>
              <span className={styles.date}>{formattedDate}</span>
            </div>
            
            <div className={styles.price}>
              <span className={styles.label}>Price:</span>
              <span className={styles.value}>{price}</span>
            </div>
          </div>

          {session.description && (
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>Description</h3>
              <p className={styles.description}>{session.description}</p>
            </div>
          )}

          {hasReview && (
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>Review</h3>
              
              <div className={styles.reviewHeader}>
                <div className={styles.rating}>
                  <span className={styles.star}>★</span>
                  <span className={styles.ratingValue}>{session.mentee_review.nps}</span>
                  <span className={styles.outOf}>/10</span>
                </div>
                
                {session.mentee_review.compliment && (
                  <div className={styles.compliment}>{session.mentee_review.compliment}</div>
                )}
              </div>
              
              {session.mentee_review.public_feedback && (
                <div className={styles.feedback}>
                  <h4 className={styles.feedbackTitle}>Public Feedback</h4>
                  <p className={styles.feedbackText}>{session.mentee_review.public_feedback}</p>
                </div>
              )}
              
              {session.mentee_review.private_feedback && (
                <div className={styles.feedback}>
                  <h4 className={styles.feedbackTitle}>Private Feedback</h4>
                  <p className={styles.feedbackText}>{session.mentee_review.private_feedback}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionModal; 