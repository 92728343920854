import React from 'react';
import styles from './ConversationListSkeleton.module.css';

const ConversationListSkeleton: React.FC = () => {
  // Generate multiple conversation card placeholders
  const conversationPlaceholderCount = 5;
  
  return (
    <div className={styles.conversationsList}>
      {Array.from({ length: conversationPlaceholderCount }).map((_, index) => (
        <div key={index} className={styles.conversationCard}>
          <div className={styles.avatarSkeleton}></div>
          <div className={styles.contentContainer}>
            <div className={styles.nameSkeleton}></div>
            <div className={styles.messageSkeleton}></div>
          </div>
          <div className={styles.timeContainer}>
            <div className={styles.timeSkeleton}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConversationListSkeleton; 