import React, { useState } from 'react';
import { format } from 'date-fns';
import styles from './SessionCard.module.css';
import SessionModal from './SessionModal';

interface SessionCardProps {
  session: any;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  // Format date
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy • h:mm a');
    } catch (e) {
      return 'Date unavailable';
    }
  };

  // Get status color
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return '#15B881'; // green
      case 'upcoming':
        return '#58a6ff'; // blue
      case 'request':
        return '#FBC95C'; // yellow
      case 'canceled':
      case 'declined':
        return '#FB836F'; // red
      default:
        return '#6e7891'; // gray
    }
  };

  // Format status text
  const formatStatus = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  // Truncate description
  const truncateDescription = (text: string, maxLength: number = 100) => {
    if (!text) return 'No description available';
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  const toggleExpand = (e: React.MouseEvent) => {
    setIsExpanded(!isExpanded);
  };

  const openModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const formattedDate = formatDate(session.slot || session.ideal_proposed_time);
  const statusColor = getStatusColor(session.status);
  const statusText = formatStatus(session.status);
  const price = session.price ? `$${Math.round(session.price)}` : 'Free';
  const hasReview = session.mentee_review && session.mentee_review.nps;
  const hasDetailedInfo = session.description || hasReview;
  
  return (
    <>
      <div className={`${styles.card} ${isExpanded ? styles.expanded : ''}`} onClick={toggleExpand}>
        <div className={styles.header}>
          <div className={styles.status}>
            <span 
              className={styles.statusDot} 
              style={{ backgroundColor: statusColor }}
            />
            <span className={styles.statusText}>{statusText}</span>
          </div>
          <div className={styles.date}>{formattedDate}</div>
        </div>
        
        {isExpanded ? (
          <div className={styles.details}>
            <div className={styles.expandedContent}>
              {session.description && (
                <p className={styles.description}>
                  {truncateDescription(session.description, 120)}
                </p>
              )}
              
              <div className={styles.priceRow}>
                <span className={styles.price}>{price}</span>
                
                {hasReview && (
                  <div className={styles.reviewBadge}>
                    <span className={styles.star}>★</span>
                    <span>{session.mentee_review.nps}</span>
                  </div>
                )}
              </div>
            </div>
            
            {hasDetailedInfo && (
              <button
                className={styles.viewDetailsButton}
                onClick={openModal}
                aria-label="View session details"
              >
                View details
              </button>
            )}
          </div>
        ) : (
          <div className={styles.summary}>
            <p className={styles.descriptionPreview}>
              {truncateDescription(session.description, 60)}
            </p>
            {hasReview && (
              <div className={styles.ratingIndicator}>
                <span className={styles.star}>★</span>
                <span>{session.mentee_review.nps}</span>
              </div>
            )}
          </div>
        )}
      </div>

      {showModal && (
        <SessionModal
          session={session}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default SessionCard; 